'use client'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { doc, getDoc } from 'firebase/firestore'
import { get } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import logo from 'src/assets/webp/logo.webp'
import { useDialogData } from 'src/context/DialogContext'
import { db } from 'src/utils/firebase'
import DropdownComponent from '../Dropdown/Dropdown.component'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function Appbar() {
  const { openDialog } = useDialogData()
  const [isSelect, setIsSelect] = useState<string>()
  const [headlineContent, setHeadlineContent] = useState<{
    title?: string
    button?: string
    hide?: boolean
    loading?: boolean
  }>({
    title: '',
    button: '',
    hide: false,
    loading: true
  })

  useEffect(() => {
    const getHeadline = async () => {
      setHeadlineContent((prev) => ({ ...prev, loading: true }))

      const headlineRef = await getDoc(
        doc(db, 'landing_page_content', 'headline')
      )
      const headlineContent = get(headlineRef.data(), 'content', '')
      setHeadlineContent({
        title: get(headlineContent, 'title', ''),
        button: get(headlineContent, 'button', ''),
        hide: get(headlineContent, 'hide', false),
        loading: false
      })
    }

    getHeadline()
  }, [])

  return (
    <Disclosure
      as="nav"
      className="bg-white"
      style={{
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        zIndex: 100,
        position: 'sticky',
        top: 0,
        width: '100%'
        // maxHeight: 70,
      }}>
      {({ open }) => (
        <div>
          <div
            className="mx-auto w-[85%] pl-10 pr-8 p-0"
            style={{ padding: 0 }}>
            <div className="relative flex items-center justify-between py-[1rem] md:py-3">
              <div className="absolute inset-y-0 right-0 flex items-center">
                {/* Mobile menu button*/}
                <Disclosure.Button
                  as="button"
                  className="inline-flex items-center justify-center rounded-md text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white lg:hidden">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center md:justify-between sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center h-16">
                  <Link href="/" shallow={true}>
                    <Image
                      className="block h-14 w-auto lg:hidden"
                      src={logo}
                      alt="logo"
                    />
                    <Image
                      className="hidden h-16  w-auto lg:block"
                      src={logo}
                      alt="Loop Logo"
                    />
                  </Link>
                </div>

                <div className="py-2 hidden lg:flex">
                  <Link href="/" shallow={true}>
                    <button
                      className={`rounded-md p-3 px-4 ml-2 font-semibold text-xs leading-8 border  text-[#6F6F6F] hover:bg-zinc-100 transition-all duration-300 ease-in-out ${
                        isSelect === 'home'
                          ? 'border-[#6F6F6F]'
                          : 'border-[#E7E7E7]'
                      }`}>
                      Home
                    </button>
                  </Link>
                  <DropdownComponent
                    isSelect={isSelect}
                    setIsSelect={setIsSelect}
                  />
                  <Link href="/case-study" shallow={true}>
                    <button
                      className={`rounded-md p-3 px-4 ml-2 font-semibold text-xs leading-8 border  text-[#6F6F6F] hover:bg-zinc-100 transition-all duration-300 ease-in-out ${
                        isSelect === 'case-study'
                          ? 'border-[#6F6F6F]'
                          : 'border-[#E7E7E7]'
                      }`}>
                      Case studies
                    </button>
                  </Link>
                  <Link href="/blog" shallow={true}>
                    <button
                      className={`rounded-md p-3 px-4 ml-2 font-semibold text-xs leading-8 border  text-[#6F6F6F] hover:bg-zinc-100 transition-all duration-300 ease-in-out ${
                        isSelect === 'blog'
                          ? 'border-[#6F6F6F]'
                          : 'border-[#E7E7E7]'
                      }`}>
                      Blogs
                    </button>
                  </Link>
                  <Link
                    href="https://app.dover.io/Loop%20AI/careers/372c60ef-0c09-4553-8255-d78deca18cb9"
                    shallow={true}>
                    <button
                      className={`rounded-md p-3 px-4 ml-2 font-semibold text-xs leading-8 border  text-[#6F6F6F] hover:bg-zinc-100 transition-all duration-300 ease-in-out ${
                        isSelect === 'careers'
                          ? 'border-[#6F6F6F]'
                          : 'border-[#E7E7E7]'
                      }`}>
                      Careers
                    </button>
                  </Link>
                </div>

                <div className="hidden lg:ml-6 lg:flex justify-center items-center">
                  <div className="flex space-x-2">
                    <Link
                      href={`${process.env.NEXT_PUBLIC_LOOP_APP_URL}`}
                      className="border rounded-lg w-[100px] py-3 text-center text-xs font-semibold leading-8">
                      Sign In
                    </Link>
                    <button
                      className="bg-[#196E82] text-white rounded-lg w-[100px] py-3 text-center text-xs font-semibold leading-8"
                      onClick={openDialog}>
                      Try It Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel
            className="lg:hidden bg-white"
            style={{
              boxShadow: '5px 6px 10px rgba(100, 100, 111, 0.2)',
              borderTop: '1px solid rgba(230, 230, 230, 1)'
            }}>
            {({ close }) => (
              <div className="space-y-1 p-[19px] flex flex-col w-full justify-between h-[83vh]">
                <div className="w-full flex flex-col gap-2">
                  <Link href="/" className="mx-[6px] mt-[6px]" shallow={true}>
                    <button
                      className={`w-full text-left rounded-md px-[25px] py-3 font-[400] text-xl leading-8 border  text-[#6F6F6F] hover:bg-zinc-100 transition-all duration-300 ease-in-out
                      ${
                        isSelect === 'home'
                          ? 'border-[#6F6F6F]'
                          : 'border-[#E7E7E7]'
                      }`}
                      onClick={() => {
                        close()
                      }}>
                      Home
                    </button>
                  </Link>
                  <DropdownComponent
                    mobile={true}
                    close={close}
                    isSelect={isSelect}
                    setIsSelect={setIsSelect}
                  />
                  <Link href="/case-study" className="mx-[6px]" shallow={true}>
                    <button
                      className={`w-full text-left rounded-md px-[25px] py-3 font-[400] text-xl leading-8 border text-[#6F6F6F] hover:bg-zinc-100 transition-all duration-300 ease-in-out
                      ${
                        isSelect === 'case-study'
                          ? 'border-[#6F6F6F]'
                          : 'border-[#E7E7E7]'
                      }`}
                      onClick={() => {
                        close()
                      }}>
                      Case studies
                    </button>
                  </Link>
                  <Link href="/blog" className="mx-[6px]" shallow={true}>
                    <button
                      className={`w-full text-left rounded-md px-[25px] py-3 font-[400] text-xl leading-8 border text-[#6F6F6F] hover:bg-zinc-100 transition-all duration-300 ease-in-out
                      ${
                        isSelect === 'blogs'
                          ? 'border-[#6F6F6F]'
                          : 'border-[#E7E7E7]'
                      }`}
                      onClick={() => {
                        close()
                      }}>
                      Blogs
                    </button>
                  </Link>
                  <Link
                    href="https://app.dover.io/Loop%20AI/careers/372c60ef-0c09-4553-8255-d78deca18cb9"
                    className="mx-[6px]"
                    shallow={true}>
                    <button
                      className={`w-full text-left rounded-md px-[25px] py-3 font-[400] text-xl leading-8 border text-[#6F6F6F] hover:bg-zinc-100 transition-all duration-300 ease-in-out
                      ${
                        isSelect === 'blogs'
                          ? 'border-[#6F6F6F]'
                          : 'border-[#E7E7E7]'
                      }`}
                      onClick={() => {
                        close()
                      }}>
                      Careers
                    </button>
                  </Link>
                </div>
                <div className="flex flex-col gap-[10px] m-[6px]">
                  <Disclosure.Button
                    as="a"
                    href={`${process.env.NEXT_PUBLIC_LOOP_APP_URL}`}
                    className={classNames(
                      'border rounded-lg py-3 text-center text-xl font-[400] leading-8'
                    )}
                    style={{ color: 'rgba(111, 111, 111, 1)' }}>
                    Sign In
                  </Disclosure.Button>
                  <Link href="/">
                    <Disclosure.Button
                      className={classNames(
                        'bg-[#196E82] w-full text-white rounded-lg py-3 text-center text-xl font-semibold leading-8'
                      )}
                      onClick={openDialog}
                      id="signup-btn">
                      Try It Now
                    </Disclosure.Button>
                  </Link>
                </div>
              </div>
            )}
          </Disclosure.Panel>

          {!headlineContent.loading && !headlineContent.hide && (
            <div className="bg-[#196E82] w-full py-2">
              <div className="mx-auto w-[85%] flex gap-2 items-center justify-center lg:justify-start">
                <p className="text-white  text-[11px] md:text-[14px]">
                  {headlineContent.title}
                </p>
                <div className="px-[6px] md:px-[9px] py-[6px] bg-[#122937] rounded-sm text-white uppercase inline text-[10px] md:text-[12px] whitespace-nowrap">
                  {headlineContent.button}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Disclosure>
  )
}
